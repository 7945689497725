// import $ from 'jquery';
import Swiper from 'swiper/bundle';
// import 'swiper/css';
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import VanillaTilt from 'vanilla-tilt';
import remodal from 'remodal';
// gsap scrollTrigerr
gsap.registerPlugin(ScrollTrigger);
const header = document.querySelector('.js-header');
// function FixedAnime(event) {
//   console.log(scrollTop)
  
//   // var headerH = $('#header').outerHeight(true);
//   // var scroll = $(window).scrollTop();
//   // if (scroll >= headerH){//headerの高さ以上になったら
//   //     $('#header').addClass('fixed');//fixedというクラス名を付与
//   //   }else{//それ以外は
//   //     $('#header').removeClass('fixed');//fixedというクラス名を除去
//   //   }
// }


window.addEventListener("scroll", (event) => {
  if (window.scrollY >= 90) {
    header.classList.add('is-scroll')
  } else {
    header.classList.remove('is-scroll')
  }
});
const isSmartPhone = () => {
  if (window.matchMedia && window.matchMedia('(max-device-width: 768px)').matches) {
    return true;
  } else {
    return false;
  }
};

const parallaxSections = gsap.utils.toArray('.js-prlx-section:not(:first-child)');
gsap.set(gsap.utils.toArray('.hero__mv'), { opacity:0, y: 10 });
gsap.to(gsap.utils.toArray('.hero__mv'), { opacity:1, y: 0, duration: 1, stagger: .25})

gsap.to(gsap.utils.toArray('.js-hero-title1'), { opacity:1, x: 0, duration: 2})
gsap.to(gsap.utils.toArray('.js-hero-title2'), { opacity:1, x: 0, duration: 2})
gsap.to(gsap.utils.toArray('.js-hero-catch'), { opacity:1, y: 0, duration: 2})

const sectionTitleElement = gsap.utils.toArray('.js-enter-active');
if (sectionTitleElement) {
    sectionTitleElement.forEach((element, index) => {
        gsap.to(element, {
            scrollTrigger: {
                trigger: element,
                start: 'top bottom',
                onEnter: () => element.classList.add('is-active'),
            }
        });
    });
}
const initVanillaTilt = () => {
  const vtilts = document.querySelectorAll(".js-vtilt"); 
  if (!vtilts) {
    return false;
  }
  VanillaTilt.init(vtilts, {
    max: 10,
    speed: 800,
    perspective: 1000,
    glare: true,
    "max-glare": 1,

  });
}
initVanillaTilt()
